ol {
    list-style: none;
    counter-reset: steps;
    
}

    ol li {
        counter-increment: steps;
        cursor: pointer
    }

        ol li::before {
            content: "";
            /*content: counter(steps);*/
            margin-right: 0.5rem;
            margin-top: .5rem;
            padding-top: 0.5rem;
            background: #ff6f00;
            color: white;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            display: inline-grid;
            place-items: center;
            line-height: 1.5em;
        }

    ol ol li::before {
        background: darkorchid;        
    }

    ol ol ol li::before {
        background: #0094ff;
    }

    .subHierachy {
        cursor: pointer;
    }

        .subHierachy:hover{
            text-decoration: underline;
        }

    .mainHierachy {
        cursor: pointer;
    }

        .mainHierachy:hover {
            text-decoration: underline;
        }