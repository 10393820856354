/* Provide sufficient contrast against white background */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');


a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


html, body, #app, #app > div {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

.float-btn-edit {
    position: absolute;
    width: 50px;
    height: 50px;
    background: antiquewhite;
    border-radius: 50%;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

    .float-btn-edit div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 48px;
    }