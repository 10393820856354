@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap);
a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }



.smart-search-result {
    position: absolute;
    width: 80%;
    max-width: 870px;
    cursor: pointer;
    overflow-y: auto;
    max-height: 400px;
    box-sizing: border-box;
    z-index: 1001;
    top: 45px;
}

.search-group {
    margin-bottom: 0px !important;
    width: 60%;
    height: 48px;
    position: relative;
}

.list-group-item:hover {
    background: #D3D3D3 !important;
}
.sidebar {
   /* position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;*/
    padding: 0px 0 0 !important;
    width: inherit;
}


ol {
    list-style: none;
    counter-reset: steps;
    
}

    ol li {
        counter-increment: steps;
        cursor: pointer
    }

        ol li::before {
            content: "";
            /*content: counter(steps);*/
            margin-right: 0.5rem;
            margin-top: .5rem;
            padding-top: 0.5rem;
            background: #ff6f00;
            color: white;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            display: inline-grid;
            place-items: center;
            line-height: 1.5em;
        }

    ol ol li::before {
        background: darkorchid;        
    }

    ol ol ol li::before {
        background: #0094ff;
    }

    .subHierachy {
        cursor: pointer;
    }

        .subHierachy:hover{
            text-decoration: underline;
        }

    .mainHierachy {
        cursor: pointer;
    }

        .mainHierachy:hover {
            text-decoration: underline;
        }
.table-tbody:hover {
    background: hsla(31, 67%, 67%, .10);
    cursor: pointer;
}

/* Provide sufficient contrast against white background */


a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


html, body, #app, #app > div {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

.float-btn-edit {
    position: absolute;
    width: 50px;
    height: 50px;
    background: antiquewhite;
    border-radius: 50%;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

    .float-btn-edit div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 48px;
    }
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 92.5vh;
}
