.smart-search-result {
    position: absolute;
    width: 80%;
    max-width: 870px;
    cursor: pointer;
    overflow-y: auto;
    max-height: 400px;
    box-sizing: border-box;
    z-index: 1001;
    top: 45px;
}

.search-group {
    margin-bottom: 0px !important;
    width: 60%;
    height: 48px;
    position: relative;
}

.list-group-item:hover {
    background: #D3D3D3 !important;
}